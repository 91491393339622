<template>
  <div></div>
</template>
<script>
import config from '@/utils/config'
import ajax from '@/utils/ajax.js'
export default {
  data() {
    return {
      code: '',
      backpagefullpath: ''
    }
  },
  created() {
  },
  mounted() {
    this.code = this.$route.query.code
    this.backpagefullpath = decodeURIComponent(localStorage.getItem('backpagefullpath'))
    if(!this.code) {
      this.doGetCode()
    }else{
      this.doLogin()
    }
  },
  methods: {
    doGetCode() {
      // console.log('doGetCode', this.$route.fullPath)

      window.location.href = config.H5_WX_LOGIN_PATH + `&state=${encodeURIComponent(this.backpagefullpath)}#wechat_redirect`
    },
    async doLogin() {
      console.log('doLogin', this.code, this.backpagefullpath)
      const params = {
        code: this.code
      }
      const {data, code} = await ajax.post('/User/login', params)
      // console.log('postlogin', data, code)
      if(code == 0) {
          // const ex = 7 * 24 * 60 * 60 * 1000
          localStorage.setItem('accessToken', data.token)
          // this.$router.push({path: this.backpagefullpath})
          this.$router.replace({path: this.backpagefullpath})
      }
    }
  }
}
</script>
